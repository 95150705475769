import { api } from "../core/api";

const uploadDocuments = async (formData) => {
  const response = await api.post(`api/document/store`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const getDocumentTypes = async () => {
  const response = await api.get("/api/document-type");
  return response.data.documentTypes;
};

const deleteDocumentTypes = async (
  developer_id,
  document_type_id,
  projectId
) => {
  const response = await api.delete(
    `/api/document/delete/document-type/${document_type_id}/project/${projectId}`
  );
  return response.data;
};

const getUploadedDocument = async (source_type_id, projectId) => {
  const response = await api.get(
    `api/document/get/document-type/${source_type_id}/project/${projectId}`
  );
  return response.data;
  // return {
  //   source_type_id: source_type_id,
  //   projectId: projectId,
  //   success: true,
  //   message: "Peticion correcta",
  //   documents: [
  //     {
  //       id: "4a161086-db09-4ab2-b46b-bea3b132b893",
  //       name: "test.txt",
  //       extension: "txt",
  //       route_file: "/var/www/public/test.txt",
  //       document_type_id: 45,
  //       developer_id: 1,
  //       comment: "Esto es una prueba de comentario",
  //       answer_id: 1,
  //       category_id: null,
  //       created_at: "2022-07-29 17:37:37",
  //       updated_at: "2022-07-29 17:42:01",
  //     },
  //     {
  //       id: "db64dca7-c1e1-4c9d-984b-521d527451dd",
  //       name: "test2.txt",
  //       extension: "txt",
  //       route_file: "/var/www/public/test2.txt",
  //       document_type_id: 2,
  //       developer_id: 1,
  //       comment: null,
  //       answer_id: null,
  //       category_id: null,
  //       created_at: "2022-07-29 17:38:30",
  //       updated_at: "2022-07-29 17:41:41",
  //     },
  //     {
  //       id: "706cfbbe-014b-466d-aa3b-fc6cf30ba247",
  //       name: "test2 (copia).txt",
  //       extension: "png",
  //       route_file: "/var/www/public/test2 (copia).txt",
  //       document_type_id: 3,
  //       developer_id: 1,
  //       comment: null,
  //       answer_id: null,
  //       category_id: null,
  //       created_at: "2022-07-29 19:32:04",
  //       updated_at: "2022-07-29 19:32:04",
  //     },
  //   ],
  // };
};

const downloadDocument = async (id, ext, projectId) => {
  api
    .get(`api/document/download/document-type/${id}/project/${projectId}`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file." + ext); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
};

export {
  uploadDocuments,
  getDocumentTypes,
  getUploadedDocument,
  deleteDocumentTypes,
  downloadDocument,
};
