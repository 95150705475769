<template>
  <div>
    <b-tabs content-class="mt-3" v-model="activeTab">
      <b-tab title="Datos del Desarrollador" @click="goTo('DeveloperData')">
        <p></p>
      </b-tab>
      <b-tab
        :disabled="nonProjectTabsDisabled"
        title="Información y Alcance del Proyecto"
        @click="goTo('ProjectInformation')"
        ><p></p
      ></b-tab>
      <b-tab
        :disabled="nonProjectTabsDisabled"
        title="Viviendas por tipología"
        @click="goTo('HousingByTypology')"
        ><p></p
      ></b-tab>
      <b-tab
        :disabled="nonProjectTabsDisabled"
        @click="goTo('ProjectDocumentation')"
        title="Documentación del proyecto"
        ><p></p
      ></b-tab>
      <b-tab
        :disabled="nonProjectTabsDisabled"
        @click="goTo('ProjectSchemes')"
        title="Planos o Esquemas"
        ><p></p
      ></b-tab>
      <b-tab
        :disabled="nonProjectTabsDisabled"
        @click="goTo('ProjectCurrentStatus')"
        title="Estado Actual del Proyecto"
        ><p></p
      ></b-tab>
      <b-tab
        :disabled="nonProjectTabsDisabled"
        @click="goTo('Escrow')"
        title="Fideicomiso"
        ><p></p
      ></b-tab>
    </b-tabs>
  </div>
</template>
<script>
export default {
  name: "tabs",
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    goTo(route) {
      this.$router.push({ name: route, params: { projectId: this.projectId } });
    },
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    nonProjectTabsDisabled() {
      return this.projectId == undefined;
    },
  },
};
</script>
<style>
.nav-tabs .nav-link {
  font-size: 0.8rem !important;
}
</style>
