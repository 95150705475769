export default Object.freeze({
  DatosDelDesarrollador: 1,
  DocumentacionTecnicaDelProyecto: 2,
  DocumentacionTerrenoDelProyecto: 3,
  PlanosOEsquemas: 4,
  EstadoActualDelProyecto: 5,
  Fideicomiso: 6,
  Fiduciaria: 7,
  Desarrollador: 8,
});
