import Swal from "sweetalert2";

export const alertSuccess = (message) => {
  Swal.fire({
    title: "Proceso exitoso",
    text: message,
    icon: "success",
    confirmButtonText: "Ok",
  });
};

export const alertError = (message) => {
  Swal.fire({
    title: "Error",
    text: message,
    icon: "error",
    confirmButtonText: "Ok",
  });
};
